<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text">
            <hb-header :divider="false">
                <hb-page-header title="Tabs Responsive">
                    <template v-slot:description>
                        <div>import { HbTabsResponsive } from 'hummingbird-aviary';</div>
                        <div class="mt-2">{{ componentDescription }}</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>
        <div>
            <div>
                <hb-tabs-responsive v-model="documentationTabActive">
                    <template v-slot:tabs>
                        <tab label="Prototype"></tab>
                        <tab label="Examples"></tab>
                        <tab label="Props, Slots and Events"></tab>
                    </template>
                </hb-tabs-responsive>
                <v-tabs-items v-model="documentationTabActive">
                    <v-tab-item :value="0">
                        <hb-card 
                            title="HbTabsResponsive Prototype + Code" 
                            class="mt-4 mb-6"
                        >
                            <div class="py-4 px-6 hb-cloud-lighter">
                                <hb-tabs-responsive 
                                    :background-color="tabsBackgroundColor" 
                                    v-model="demoTabActive"
                                >
                                    <template v-slot:tabs>
                                        <tab 
                                            v-for="(tab, i) in demoTabOptions" 
                                            :key="'tab' + i" :label="tab.label"
                                            :disabled="tab.disabled" 
                                            :badgeCount="tab.badgeCount || null"
                                            :tooltipHeader="tab.tooltipHeader || null"
                                            :tooltipBody="tab.tooltipBody || null"
                                        >
                                        </tab>
                                    </template>
                                </hb-tabs-responsive>
                                <v-divider></v-divider>
                                <v-tabs-items v-model="demoTabActive">
                                    <v-tab-item 
                                        v-for="(tab, i) in demoTabOptions" 
                                        :key="'tab' + i"
                                    >
                                        <div class="py-4">
                                            {{ tab.label }} - Lorem ipsum dolor sit amet, consectetur adipiscing
                                            elit. Proin
                                            elit ipsum, dapibus eu nisi et, efficitur feugiat turpis. Aenean
                                            volutpat libero
                                            ut nisl pretium porta. Mauris tellus lacus, varius ac enim at, luctus
                                            volutpat
                                            erat. Donec blandit auctor est sed imperdiet. Fusce finibus viverra mi,
                                            sed
                                            bibendum dolor. Aenean feugiat ipsum non sodales accumsan. Sed nec metus
                                            quis
                                            mauris tristique fringilla. Aliquam justo ex, viverra et bibendum vel,
                                            elementum
                                            quis turpis.
                                        </div>
                                    </v-tab-item>
                                </v-tabs-items>
                            </div>
                        </hb-card>
                        <v-row no-gutters>
                            <v-col 
                                cols="12" 
                                lg="4" 
                                class="pr-lg-6"
                            >
                                <hb-card title="HbTabsResponsive Props">
                                    <hb-form label="Background Color">
                                        <HbTextField 
                                            v-model="tabsBackgroundColor" 
                                            placeholder="Enter Background Color"
                                            :clearable="false"
                                        />
                                    </hb-form>
                                </hb-card>
                            </v-col>
                            <v-col 
                                cols="12" 
                                lg="8" 
                                class="mt-6 mt-lg-0"
                            >
                                <hb-card 
                                    title="HbTabsResponsive <v-slot:tabs>" 
                                    class="mb-6"
                                >
                                    <div class="pb-6 mx-6">
                                        <hb-data-table-header>
                                            <template v-slot:description>
                                                Tabs can be added or
                                                edited or deleted here to test the functionality.
                                                For full list of options refer Props, Slots and Events. Scroll down to
                                                see generated code
                                            </template>
                                            <template v-slot:actions>
                                                <hb-btn 
                                                    small 
                                                    color="secondary" 
                                                    @click="dialog = true"
                                                >
                                                    Add New Tab
                                                </hb-btn>
                                                <hb-modal 
                                                    :title="formTitle" 
                                                    v-model="dialog"
                                                    size="medium"
                                                >
                                                    <template v-slot:content>
                                                        <div>
                                                            <hb-form 
                                                                label="Label" 
                                                                required
                                                            >
                                                                <hb-text-field 
                                                                    v-validate="'required'"
                                                                    data-vv-scope="default" 
                                                                    data-vv-name="label"
                                                                    data-vv-as="Label"
                                                                    :error="errors.has('default.label')"
                                                                    v-model="editedItem.label"
                                                                    placeholder="Enter Label"
                                                                >
                                                                </hb-text-field>
                                                            </hb-form>
                                                            <hb-form label="Disabled">
                                                                <hb-select 
                                                                    :items="[true, false]" 
                                                                    type="boolean"
                                                                    v-model="editedItem.disabled"
                                                                    placeholder="Select Disabled Value"
                                                                >
                                                                </hb-select>
                                                            </hb-form>
                                                            <hb-form label="Badge Count">
                                                                <template v-slot:tooltipBody>
                                                                    The Tooltip Header and Body fields are disabled when
                                                                    Badge Count is selected, as they cannot coexist.
                                                                </template>
                                                                <hb-text-field 
                                                                    type="number" 
                                                                    @input="badgeCountChanged"
                                                                    v-model="editedItem.badgeCount"
                                                                    placeholder="Enter Badge Count"
                                                                >
                                                                </hb-text-field>
                                                            </hb-form>
                                                            <hb-form label="Tooltip Header">
                                                                <hb-text-field 
                                                                    :disabled="editedItem.badgeCount > 0"
                                                                    v-model="editedItem.tooltipHeader"
                                                                    placeholder="Enter Tooltip Header"
                                                                >
                                                                </hb-text-field>
                                                            </hb-form>
                                                            <hb-form 
                                                                label="Tooltip Body"
                                                                last
                                                            >
                                                                <hb-text-field 
                                                                    :disabled="editedItem.badgeCount > 0"
                                                                    v-model="editedItem.tooltipBody"
                                                                    placeholder="Enter Tooltip Body"
                                                                >
                                                                </hb-text-field>
                                                            </hb-form>
                                                        </div>
                                                    </template>
                                                    <template v-slot:right-actions>
                                                        <hb-btn 
                                                            color="primary" 
                                                            @click="save"
                                                        >
                                                            Save
                                                        </hb-btn>
                                                    </template>
                                                </hb-modal>
                                            </template>
                                        </hb-data-table-header>
                                        <hb-data-table 
                                            :headers="headers"
                                            :items="demoTabOptions"
                                            sort-by="label"
                                        >
                                            <template v-slot:item.actions="{ item }">
                                                <hb-menu 
                                                    options 
                                                    class="d-flex justify-end"
                                                >
                                                    <v-list>
                                                        <v-list-item 
                                                            :ripple="false" 
                                                            @click="editItem(item)"
                                                        >
                                                            <v-list-item-title>Edit</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item 
                                                            :ripple="false" 
                                                            @click="deleteItem(item)"
                                                        >
                                                            <v-list-item-title>Delete</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </hb-menu>
                                            </template>
                                            <!-- <template v-slot:no-data>
                                                <v-btn color="primary" @click="initialize">
                                                    Reset
                                                </v-btn>
                                            </template> -->
                                        </hb-data-table>
                                    </div>
                                </hb-card>
                            </v-col>
                        </v-row>
                        <hb-card 
                            class="pa-6" 
                            no-title
                        >
                            <v-row>
                                <v-col 
                                    cols="12" 
                                    lg="6" 
                                    no-gutters 
                                    class="mb-1"
                                >
                                    <hb-card color="#E0F5F5">
                                        <template #title>
                                            Code
                                        </template>
                                        <div id="code">
                                            <pre v-pre>

        &lt;hb-tabs-responsive 
            :background-color="tabsBackgroundColor" 
            v-model="demoTabActive"
        >
            &lt;template v-slot:tabs>
                &lt;tab
                    v-for="(tab, i) in demoTabOptions"
                    :key="'tab' + i"
                    :label="tab.label"
                    :disabled="tab.disabled"
                    :badgeCount="tab.badgeCount || null"
                    :tooltipHeader="tab.tooltipHeader || null"
                    :tooltipBody="tab.tooltipBody || null"
                >
                &lt;/tab>
            &lt;/template>
        &lt;/hb-tabs-responsive>

        &lt;v-tabs-items v-model="demoTabActive">
            &lt;v-tab-item 
                v-for="(tab, i) in demoTabOptions" 
                :key="'tab' + i" 
            >
                &lt;v-container>
                    &lt;v-row>
                        {{ tab.label }} - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                        elit ipsum, dapibus eu nisi et, efficitur feugiat turpis. Aenean volutpat libero
                        ut nisl pretium porta. Mauris tellus lacus, varius ac enim at, luctus volutpat
                        erat. Donec blandit auctor est sed imperdiet. Fusce finibus viverra mi, sed
                        bibendum dolor. Aenean feugiat ipsum non sodales accumsan. Sed nec metus quis
                        mauris tristique fringilla. Aliquam justo ex, viverra et bibendum vel, elementum
                        quis turpis.
                    &lt;/v-row>
                &lt;/v-container>
            &lt;/v-tab-item>
        &lt;/v-tabs-items>
                                            </pre>
                                        </div>
                                    </hb-card>
                                </v-col>

                                <v-col 
                                    cols="12" 
                                    lg="6" 
                                    no-gutters 
                                    class="mb-1"
                                >
                                    <hb-card color="#E0F5F5">
                                        <template #title>
                                            Data
                                        </template>
                                        <pre class="px-4">

        let tabsBackgroundColor: '{{ tabsBackgroundColor }}'',
        let demoTabOptions = [
            <template v-for="(tab, index) in demoTabOptions">
            {
                label: '{{ tab.label }}',
                disabled: {{ tab.disabled }},
                badgeCount: {{ tab.badgeCount }},
                tooltipHeader: '{{ tab.tooltipHeader }}',
                tooltipBody: '{{ tab.tooltipBody }}'
            },
            </template>
        ]
                                        </pre>
                                    </hb-card>
                                </v-col>
                            </v-row>
                        </hb-card>
                    </v-tab-item>
                    <v-tab-item :value="1">
                        <div class="d-flex flex-wrap">
                            <v-col 
                                cols="12" 
                                md="6"
                            >
                                <hb-card title="Simple Tabs">
                                    <div class="py-4 px-6 hb-cloud-lighter">
                                        <hb-tabs-responsive v-model="simpleTabActive">
                                            <template v-slot:tabs>
                                                <tab 
                                                    v-for="(tab, i) in exampleTabsSimple" 
                                                    :key="'simple_tab_' + i"
                                                    :label="tab.label"
                                                >
                                                </tab>
                                            </template>
                                        </hb-tabs-responsive>
                                        <v-divider></v-divider>
                                        <v-tabs-items v-model="simpleTabActive">
                                            <v-tab-item 
                                                v-for="(tab, i) in exampleTabsSimple"
                                                :key="'simple_tab_items' + i"
                                            >
                                                <v-container>
                                                    <v-row>
                                                        {{ tab.label }} - Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing
                                                        elit. Proin
                                                        elit ipsum, dapibus eu nisi et, efficitur feugiat turpis. Aenean
                                                        volutpat libero
                                                        ut nisl pretium porta. Mauris tellus lacus, varius ac enim at,
                                                        luctus
                                                        volutpat
                                                        erat. Donec blandit auctor est sed imperdiet. Fusce finibus
                                                        viverra mi,
                                                        sed
                                                        bibendum dolor. Aenean feugiat ipsum non sodales accumsan. Sed
                                                        nec metus
                                                        quis
                                                        mauris tristique fringilla. Aliquam justo ex, viverra et
                                                        bibendum vel,
                                                        elementum
                                                        quis turpis.
                                                    </v-row>
                                                </v-container>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </div>
                                    <hb-card color="#E0F5F5">
                                        <template #title>
                                            Code + Data
                                        </template>
                                        <pre class="tabs-responsive-example-code" v-pre>

        &lt;hb-tabs-responsive v-model="activeTab">
            &lt;template v-slot:tabs>
                &lt;tab
                    v-for="(tab, i) in tabOptions"
                    :key="'simple_tab_' + i"
                    :label="tab.label"
                >
                &lt;/tab>
            &lt;/template>
        &lt;/hb-tabs-responsive>

        &lt;v-tabs-items v-model="activeTab">
            &lt;v-tab-item 
                v-for="(tab, i) in tabOptions" 
                :key="'simple_tab_items' + i"
            >
                &lt;v-container>
                    &lt;v-row>
                        {{ tab.label }} - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                        elit ipsum,
                        dapibus eu nisi et, efficitur feugiat turpis. Aenean volutpat libero ut nisl pretium
                        porta.
                        Mauris tellus lacus, varius ac enim at, luctus volutpat erat. Donec blandit auctor
                        est sed
                        imperdiet. Fusce finibus viverra mi, sed bibendum dolor. Aenean feugiat ipsum non
                        sodales
                        accumsan. Sed nec metus quis mauris tristique fringilla. Aliquam justo ex, viverra
                        et
                        bibendum vel, elementum quis turpis.
                    &lt;/v-row>
                &lt;/v-container>
            &lt;/v-tab-item>
        &lt;/v-tabs-items>

        let tabOptions = [
            {
                label: 'Space 200'
            },
            {
                label: 'Space 201'
            },
            {
                label: 'Space 202'
            },
            {
                label: 'Space 203'
            },
            {
                label: 'Space 204'
            },
            {
                label: 'Space 205'
            },
            {
                label: 'Space 206'
            },
            {
                label: 'Space 207'
            }
        ]
                                        </pre>
                                    </hb-card>
                                </hb-card>
                            </v-col>
                            <v-col 
                                cols="12" 
                                md="6"
                            >
                                <hb-card title="Tabs with Badges">
                                    <div class="py-4 px-6 hb-cloud-lighter">
                                        <hb-tabs-responsive v-model="tabWithBadgeActive">
                                            <template v-slot:tabs>
                                                <tab 
                                                    v-for="(tab, i) in exampleTabsWithBadge"
                                                    :key="'tabs_with_badges_' + i" 
                                                    :label="tab.label"
                                                    :badgeCount="tab.badgeCount || null"
                                                >
                                                </tab>
                                            </template>
                                        </hb-tabs-responsive>
                                        <v-divider></v-divider>
                                        <v-tabs-items v-model="tabWithBadgeActive">
                                            <v-tab-item 
                                                v-for="(tab, i) in exampleTabsWithBadge"
                                                :key="'tabs_with_badges_' + i"
                                            >
                                                <v-container>
                                                    <v-row>
                                                        {{ tab.label }} - Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing
                                                        elit. Proin
                                                        elit ipsum, dapibus eu nisi et, efficitur feugiat turpis. Aenean
                                                        volutpat libero
                                                        ut nisl pretium porta. Mauris tellus lacus, varius ac enim at,
                                                        luctus
                                                        volutpat
                                                        erat. Donec blandit auctor est sed imperdiet. Fusce finibus
                                                        viverra mi,
                                                        sed
                                                        bibendum dolor. Aenean feugiat ipsum non sodales accumsan. Sed
                                                        nec metus
                                                        quis
                                                        mauris tristique fringilla. Aliquam justo ex, viverra et
                                                        bibendum vel,
                                                        elementum
                                                        quis turpis.
                                                    </v-row>
                                                </v-container>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </div>
                                    <hb-card color="#E0F5F5">
                                        <template #title>
                                            Code + Data
                                        </template>
                                        <pre class="tabs-responsive-example-code" v-pre>

        &lt;hb-tabs-responsive v-model="activeTab">
            &lt;template v-slot:tabs>
                &lt;tab
                    v-for="(tab, i) in tabOptions"
                    :key="'tabs_with_badges_' + i"
                    :label="tab.label"
                    :badgeCount="tab.badgeCount || null"
                >
                &lt;/tab>
            &lt;/template>
        &lt;/hb-tabs-responsive>

        &lt;v-tabs-items v-model="activeTab">
            &lt;v-tab-item 
                v-for="(tab, i) in tabOptions" 
                :key="'tabs_with_badges_items' + i"
            >
                &lt;v-container>
                    &lt;v-row>
                        {{ tab.label }}- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit
                        ipsum,
                        dapibus eu nisi et, efficitur feugiat turpis. Aenean volutpat libero ut nisl pretium
                        porta.
                        Mauris tellus lacus, varius ac enim at, luctus volutpat erat. Donec blandit auctor
                        est sed
                        imperdiet. Fusce finibus viverra mi, sed bibendum dolor. Aenean feugiat ipsum non
                        sodales
                        accumsan. Sed nec metus quis mauris tristique fringilla. Aliquam justo ex, viverra
                        et
                        bibendum vel, elementum quis turpis.
                    &lt;/v-row>
                &lt;/v-container>
            &lt;/v-tab-item>
        &lt;/v-tabs-items>

        let tabOptions = [
            {
                label: 'Space 200'
            },
            {
                label: 'Space 201',
                badgeCount: 2
            },
            {
                label: 'Space 202'
            },
            {
                label: 'Space 203'
            },
            {
                label: 'Space 204'
            },
            {
                label: 'Space 205'
            },
            {
                label: 'Space 206'
            },
            {
                label: 'Space 207'
            }
        ]
                                        </pre>
                                    </hb-card>
                                </hb-card>
                            </v-col>
                            <v-col 
                                cols="12" 
                                md="6"
                            >
                                <hb-card title="Tabs with Tooltips">
                                    <div class="py-4 px-6 hb-cloud-lighter">
                                        <hb-tabs-responsive v-model="tabWithTooltipActive">
                                            <template v-slot:tabs>
                                                <tab 
                                                    v-for="(tab, i) in exampleTabsWithToolTip"
                                                    :key="'tabs_with_tooltip_' + i" 
                                                    :label="tab.label"
                                                    :tooltipHeader="tab.tooltipHeader || null"
                                                    :tooltipBody="tab.tooltipBody || null"
                                                >
                                                </tab>
                                            </template>
                                        </hb-tabs-responsive>
                                        <v-divider></v-divider>
                                        <v-tabs-items v-model="tabWithTooltipActive">
                                            <v-tab-item 
                                                v-for="(tab, i) in exampleTabsWithToolTip"
                                                :key="'tabs_with_tooltip_items' + i"
                                            >
                                                <v-container>
                                                    <v-row>
                                                        {{ tab.label }} - Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing
                                                        elit. Proin
                                                        elit ipsum, dapibus eu nisi et, efficitur feugiat turpis. Aenean
                                                        volutpat libero
                                                        ut nisl pretium porta. Mauris tellus lacus, varius ac enim at,
                                                        luctus
                                                        volutpat
                                                        erat. Donec blandit auctor est sed imperdiet. Fusce finibus
                                                        viverra mi,
                                                        sed
                                                        bibendum dolor. Aenean feugiat ipsum non sodales accumsan. Sed
                                                        nec metus
                                                        quis
                                                        mauris tristique fringilla. Aliquam justo ex, viverra et
                                                        bibendum vel,
                                                        elementum
                                                        quis turpis.
                                                    </v-row>
                                                </v-container>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </div>
                                    <hb-card color="#E0F5F5">
                                        <template #title>
                                            Code + Data
                                        </template>
                                            <pre class="tabs-responsive-example-code" v-pre>

        &lt;hb-tabs-responsive v-model="activeTab">
            &lt;template v-slot:tabs>
                &lt;tab
                    v-for="(tab, i) in tabOptions"
                    :key="'tabs_with_tooltip_' + i"
                    :label="tab.label"
                    :tooltipHeader="tab.tooltipHeader || null"
                    :tooltipBody="tab.tooltipBody || null"
                >
                &lt;/tab>
            &lt;/template>
        &lt;/hb-tabs-responsive>

        &lt;v-tabs-items v-model="activeTab">
            &lt;v-tab-item 
                v-for="(tab, i) in tabOptions" 
                :key="'tabs_with_tooltip_items' + i"
            >
                &lt;v-container>
                    &lt;v-row>
                        {{ tab.label }} - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                        elit ipsum,
                        dapibus eu nisi et, efficitur feugiat turpis. Aenean volutpat libero ut nisl pretium
                        porta.
                        Mauris tellus lacus, varius ac enim at, luctus volutpat erat. Donec blandit auctor
                        est sed
                        imperdiet. Fusce finibus viverra mi, sed bibendum dolor. Aenean feugiat ipsum non
                        sodales
                        accumsan. Sed nec metus quis mauris tristique fringilla. Aliquam justo ex, viverra
                        et
                        bibendum vel, elementum quis turpis.
                    &lt;/v-row>
                &lt;/v-container>
            &lt;/v-tab-item>
        &lt;/v-tabs-items>

        let tabOptions = [
            {
                label: 'Space 200'
            },
            {
                label: 'Space 201',
                tooltipHeader: 'Tooltip Header',
                tooltipBody: 'Tooltip Body'
            },
            {
                label: 'Space 202'
            },
            {
                label: 'Space 203'
            },
            {
                label: 'Space 204'
            },
            {
                label: 'Space 205'
            },
            {
                label: 'Space 206'
            },
            {
                label: 'Space 207'
            }
        ]
                                            </pre>
                                    </hb-card>
                                </hb-card></v-col>
                            <v-col 
                                cols="12" 
                                md="6"
                            >
                                <hb-card title="Assign a Specific Tab as Active">
                                    <div class="py-4 px-6 hb-cloud-lighter">
                                        <hb-tabs-responsive v-model="tabWithDefaultTabActive">
                                            <template v-slot:tabs>
                                                <tab 
                                                    v-for="(tab, i) in exampleTabsWithActiveTab"
                                                    :key="'default_tab_' + i" 
                                                    :defaultTab="'#default_tab_' + i"
                                                    :label="tab.label" 
                                                    :tooltipHeader="tab.tooltipHeader || null"
                                                    :tooltipBody="tab.tooltipBody || null"
                                                >
                                                </tab>
                                            </template>
                                        </hb-tabs-responsive>
                                        <v-divider></v-divider>
                                        <v-tabs-items v-model="tabWithDefaultTabActive">
                                            <v-tab-item 
                                                v-for="(tab, i) in exampleTabsWithActiveTab"
                                                :value="'default_tab_' + i"
                                            >
                                                <v-container>
                                                    <v-row>
                                                        {{ tab.label }} - Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing
                                                        elit. Proin
                                                        elit ipsum, dapibus eu nisi et, efficitur feugiat turpis. Aenean
                                                        volutpat libero
                                                        ut nisl pretium porta. Mauris tellus lacus, varius ac enim at,
                                                        luctus
                                                        volutpat
                                                        erat. Donec blandit auctor est sed imperdiet. Fusce finibus
                                                        viverra mi,
                                                        sed
                                                        bibendum dolor. Aenean feugiat ipsum non sodales accumsan. Sed
                                                        nec metus
                                                        quis
                                                        mauris tristique fringilla. Aliquam justo ex, viverra et
                                                        bibendum vel,
                                                        elementum
                                                        quis turpis.
                                                    </v-row>
                                                </v-container>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </div>
                                    <hb-card color="#E0F5F5">
                                        <template #title>
                                            Code + Data
                                        </template>
                                        <pre class="tabs-responsive-example-code" v-pre>

        &lt;hb-tabs-responsive v-model="activeTab">
            &lt;template v-slot:tabs>
                &lt;tab
                    v-for="(tab, i) in tabOptions"
                    :key="'default_tab_' + i"
                    :label="tab.label"
                    :defaultTab="'#default_tab_' + i"
                >
                &lt;/tab>
            &lt;/template>
        &lt;/hb-tabs-responsive>

        &lt;v-tabs-items v-model="activeTab">
            &lt;v-tab-item 
                v-for="(tab, i) in tabOptions" 
                :key="'tab' + i"
            >
                &lt;v-container>
                    &lt;v-row>
                        {{ tab.label }} - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                        elit ipsum,
                        dapibus eu nisi et, efficitur feugiat turpis. Aenean volutpat libero ut nisl pretium
                        porta.
                        Mauris tellus lacus, varius ac enim at, luctus volutpat erat. Donec blandit auctor
                        est sed
                        imperdiet. Fusce finibus viverra mi, sed bibendum dolor. Aenean feugiat ipsum non
                        sodales
                        accumsan. Sed nec metus quis mauris tristique fringilla. Aliquam justo ex, viverraet
                        bibendum vel, elementum quis turpis.
                    &lt;/v-row>
                &lt;/v-container>
            &lt;/v-tab-item>
        &lt;/v-tabs-items>

        let activeTab = 'default_tab_2'
        let tabOptions = [
            {
                label: 'Space 200'
            },
            {
                label: 'Space 201'
            },
            {
                label: 'Space 202'
            },
            {
                label: 'Space 203'
            },
            {
                label: 'Space 204'
            },
            {
                label: 'Space 205'
            },
            {
                label: 'Space 206'
            },
            {
                label: 'Space 207'
            }
        ]
                                        </pre>
                                    </hb-card>
                                </hb-card>
                            </v-col>
                            <v-col 
                                cols="12" 
                                md="6"
                            >
                                <hb-card title="Assign a Tab as a Link">
                                    <div class="py-4 px-6 hb-cloud-lighter">
                                        <hb-tabs-responsive v-model="tabWithLink">
                                            <template v-slot:tabs>
                                                <tab 
                                                    v-for="(tab, i) in exampleTabsWithTabAsLink"
                                                    :key="'tabs_with_link_' + i" 
                                                    :label="tab.label" 
                                                    :to="tab.to"
                                                >
                                                </tab>
                                            </template>
                                        </hb-tabs-responsive>
                                        <v-divider></v-divider>
                                    </div>
                                    <hb-card color="#E0F5F5">
                                        <template #title>
                                            Code + Data
                                        </template>
                                        <pre class="tabs-responsive-example-code" v-pre>

        &lt;hb-tabs-responsive v-model="activeTab">
            &lt;template v-slot:tabs>
                &lt;tab
                    v-for="(tab, i) in tabOptions"
                    :key="'tabs_with_link_' + i"
                    :label="tab.label"
                    :to="tab.to"
                >
                &lt;/tab>
            &lt;/template>
        &lt;/hb-tabs-responsive>

        let tabOptions = [
            {
                label: 'Tabs Responsive',
                to:'/design-system/tabs-responsive'
            },
            {
                label: 'Blade Headers',
                to:'/design-system/blade-headers'
            },
            {
                label: 'Bottom Action Bar',
                to:'/design-system/bottom-action-bar'
            },
            {
                label: 'Breadcrumbs',
                to:'/design-system/breadcrumbs'
            },
            {
                label: 'Buttons',
                to:'/design-system/buttons'
            },{
                label: 'Checkboxes',
                to:'/design-system/checkboxes'
            },
            {
                label: 'Communications',
                to:'/design-system/communications'
            }
        ]
                                        </pre>
                                    </hb-card>
                                </hb-card>
                            </v-col>
                            <!-- <v-col 
                                cols="12" 
                                md="6"
                            >
                                <hb-card title="Assign a Tab as a Link with Target Attribute">
                                    <div class="py-4 px-6 hb-cloud-lighter">
                                        <hb-tabs-responsive v-model="tabWithTargetLink">
                                            <template v-slot:tabs>
                                                <tab 
                                                    v-for="(tab, i) in exampleTabsWithTabAsTargetLink"
                                                    :key="'tabs_with_link_target_' + i" 
                                                    :label="tab.label"
                                                    :href="tab.to" 
                                                    :target="tab.target"
                                                >
                                                </tab>
                                            </template>
                                        </hb-tabs-responsive>
                                        <v-divider></v-divider>
                                    </div>
                                    <hb-card color="#E0F5F5">
                                        <template #title>
                                            Code + Data
                                        </template>
                                        <pre class="tabs-responsive-example-code" v-pre>

        &lt;hb-tabs-responsive v-model="activeTab">
            &lt;template v-slot:tabs>
                &lt;tab
                    v-for="(tab, i) in tabOptions"
                    :key="'tabs_with_link_target_' + i"
                    :label="tab.label"
                    :href="tab.to"
                    :target="tab.target"
                >
                &lt;/tab>
            &lt;/template>
        &lt;/hb-tabs-responsive>

        let tabOptions = [
            {
                label: 'Tabs Responsive',
                to:'/design-system/tabs-responsive',
                target:'_blank'
            },
            {
                label: 'Blade Headers',
                to:'/design-system/blade-headers',
                target:'_blank'
            },
            {
                label: 'Bottom Action Bar',
                to:'/design-system/bottom-action-bar',
                target:'_blank'
            },
            {
                label: 'Breadcrumbs',
                to:'/design-system/breadcrumbs',
                target:'_blank'
            },
            {
                label: 'Buttons',
                to:'/design-system/buttons',
                target:'_blank'
            },{
                label: 'Checkboxes',
                to:'/design-system/checkboxes',
                target:'_blank'
            },
            {
                label: 'Communications',
                to:'/design-system/communications',
                target:'_blank'
            }
        ]
                                        </pre>
                                    </hb-card>
                                </hb-card>
                            </v-col> -->
                        </div>
                    </v-tab-item>
                    <v-tab-item :value="2">
                        <hb-card 
                            title="HbTabsResponsive Props" 
                            class="my-6 pb-1"
                        >
                            <hb-data-table 
                                :headers="hbTabsResponsivePropHeaders" 
                                :items="hbTabsResponsivePropItems"
                                class="pa-6"
                            >
                            </hb-data-table>
                        </hb-card>
                        <hb-card 
                            title="v-slot:tabs options" 
                            class="my-6 pb-1"
                        >
                            <hb-data-table 
                                :headers="tabsHeaders" 
                                :items="tabsPropItems" 
                                class="pa-6"
                            >
                            </hb-data-table>
                        </hb-card>
                        <hb-card 
                            title="HbTabsResponsive Events" 
                            class="my-6 pb-1"
                        >
                            <hb-data-table 
                                :headers="eventHeaders" 
                                :items="eventItems" 
                                class="pa-6"
                            >
                            </hb-data-table>
                        </hb-card>
                        <hb-card 
                            title="All Other Props, Slots, &amp; Events" 
                            class="my-6 pb-1"
                        >
                            <hb-data-table 
                                :headers="vuetifyHeaders" 
                                :items="vuetifyItems" 
                                class="pa-6"
                            >
                                <template v-slot:item.resource="{ item }">
                                    <hb-link 
                                        :href="item.resource" 
                                        target="_blank"
                                    >
                                        {{ item.resource }}
                                    </hb-link>
                                </template>
                            </hb-data-table>
                        </hb-card>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
        <hb-global-notification 
            v-model="snackbar" 
            :type="type" 
            :list="type === 'error' ? errors.items : []"
        >
        </hb-global-notification>
    </div>
</template>
<script type="text/babel">
import { cloneDeep } from "lodash";
const referenceTabs = [
    {
        label: 'Space 200',
        disabled: false,
        badgeCount: 0,
        tooltipHeader: '',
        tooltipBody: '',
    },
    {
        label: 'Space 201',
        disabled: false,
        badgeCount: 0,
        tooltipHeader: '',
        tooltipBody: '',
    },
    {
        label: 'Space 202',
        disabled: false,
        badgeCount: 0,
        tooltipHeader: '',
        tooltipBody: '',
    },
    {
        label: 'Space 203',
        disabled: false,
        badgeCount: 0,
        tooltipHeader: '',
        tooltipBody: '',
    },
    {
        label: 'Space 204',
        disabled: false,
        badgeCount: 0,
        tooltipHeader: '',
        tooltipBody: '',
    },
    {
        label: 'Space 205',
        disabled: false,
        badgeCount: 0,
        tooltipHeader: '',
        tooltipBody: '',
    },
    {
        label: 'Space 206',
        disabled: false,
        badgeCount: 0,
        tooltipHeader: '',
        tooltipBody: '',
    },
    {
        label: 'Space 207',
        disabled: false,
        badgeCount: 0,
        tooltipHeader: '',
        tooltipBody: '',
    }
]
const referenceTabsForLinks = [{
    label: 'Tabs Responsive',
    to: '/design-system/tabs-responsive',
    target: '_blank'
},
{
    label: 'Blade Headers',
    to: '/design-system/blade-headers',
    target: '_blank'
},
{
    label: 'Bottom Action Bar',
    to: '/design-system/bottom-action-bar',
    target: '_blank'
},
{
    label: 'Breadcrumbs',
    to: '/design-system/breadcrumbs',
    target: '_blank'
},
{
    label: 'Buttons',
    to: '/design-system/buttons',
    target: '_blank'
}, {
    label: 'Checkboxes',
    to: '/design-system/checkboxes',
    target: '_blank'
},
{
    label: 'Communications',
    to: '/design-system/communications',
    target: '_blank'
}]
export default {
    name: "DesignSystemTabs",
    data: function () {
        return {
            snackbar: false,
            type: '',
            componentDescription: `This Aviary component enables developers to build responsive
                            tab interfaces that adjust smoothly to different screen sizes. When the tabs overflow the
                            container, navigation arrows appear on the right to scroll through the tabs, while a
                            hamburger menu on the left displays list of tabs as menu items for selecting any
                            hidden of off-screen tab items. Developers can easily add badge numbers and tooltips beside each tab, providing
                            a flexible and user-friendly way to enhance tab functionality.`,
            tabsBackgroundColor: '#F9FAFB',
            tabsHeaders: [
                { text: 'Name', value: 'name' },
                { text: 'Type', value: 'type' },
                { text: 'Default', value: 'default', width: '110' },
                { text: 'Description', value: 'description' }
            ],
            tabsPropItems: [
                { name: 'label', type: 'string', default: '', description: 'Label value given to tab or menu item.' },
                { name: 'disabled', type: 'boolean', default: 'false', description: 'Makes tab or menu item disabled.' },
                { name: 'badgeCount', type: 'number', default: 0, description: 'If number value is given that is greater than 0, a badge appear beside tab label with the number.' },
                { name: 'badgeColor', type: 'string', default: '#F26500', description: 'Badge color value. Default is #F26500 and usually should not be changed.' },
                { name: 'tooltipHeader', type: 'string', default: 'undefined', description: 'If given a value, then a tooltip icon appears beside tab label. On hover the tooltip header value is shown.' },
                { name: 'tooltipBody', type: 'string', default: 'undefined', description: 'If given a value, then a tooltip icon appears beside tab label. On hover the tooltip content value is shown.' },
                { name: 'to', type: 'string', default: 'undefined', description: 'Denotes the target route of the link applied to tab. You can find more information about the to prop on the vue-router documentation.' }
            ],
            eventHeaders: [
                { text: 'Name', value: 'name' },
                { text: 'Description', value: 'description' },
            ],
            eventItems: [
                { name: '@tab-clicked', description: 'Event emitted when clicked on a tab or menu item.' },
            ],
            hbTabsResponsivePropHeaders: [
                { text: 'Name', value: 'name' },
                { text: 'Type', value: 'type' },
                { text: 'Default', value: 'default', width: '110' },
                { text: 'Description', value: 'description' },
            ],
            hbTabsResponsivePropItems: [
                { name: 'background-color', type: 'string', default: '#F9FAFB', description: 'Changes the default background color. The default background color is #F9FAFB and usually should not be changed.' },
                { name: 'slider-color', type: 'string', default: '#00848E', description: 'Changes the default slider color. The default slider color is #00848E and usually should not be changed.' },
                { name: 'hide-menu', type: 'boolean', default: 'false', description: 'If set to true, this will always hide the menu irrespective of screen sizes and usually should not be changed.' },
                { name: 'color', type: 'string', default: '#101318', description: 'Changes the default color. The default color is #101318 and usually should not be changed.' }
            ],
            vuetifyHeaders: [
                { text: 'Name', value: 'name' },
                { text: 'Description', value: 'description' },
                { text: 'Resource', value: 'resource' },
            ],
            vuetifyItems: [
                { name: 'all vuetify props, slots, & events', description: 'All the same vuetify props, slots, & events for "<v-tabs>" can be used for "<hb-tabs-responsive>". See the vuetify documentation link in the next column for the list of available props, slots, & events.', resource: 'https://v2.vuetifyjs.com/en/api/v-tab/' },
            ],
            dialog: false,
            headers: [
                {
                    text: 'Label',
                    align: 'start',
                    sortable: false,
                    value: 'label',
                },
                { text: 'Disabled', value: 'disabled', sortable: false },
                { text: 'Badge Count', value: 'badgeCount', sortable: false },
                { text: 'Tooltip Header', value: 'tooltipHeader', sortable: false },
                { text: 'Tooltip Body', value: 'tooltipBody' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            demoTabOptions: cloneDeep(referenceTabs),
            exampleTabsSimple: [...referenceTabs],
            exampleTabsWithBadge: referenceTabs.map((referenceTab, index) => index == 1 ? { ...referenceTab, ...{ badgeCount: 2 } } : referenceTab),
            exampleTabsWithToolTip: referenceTabs.map((referenceTab, index) => index == 1 ? { ...referenceTab, ...{ tooltipHeader: 'Tooltip Header', tooltipBody: 'Tooltip Body' } } : referenceTab),
            exampleTabsWithActiveTab: [...referenceTabs],
            exampleTabsWithTabAsLink: [...referenceTabsForLinks],
            exampleTabsWithTabAsTargetLink: [...referenceTabsForLinks],
            editedIndex: -1,
            editedItem: {
                label: '',
                disabled: false,
                badgeCount: 0,
                tooltipHeader: '',
                tooltipBody: '',
            },
            defaultItem: {
                label: '',
                disabled: false,
                badgeCount: '',
                tooltipHeader: '',
                tooltipBody: '',
            },
            documentationTabActive: '',
            demoTabActive: '',
            simpleTabActive: '',
            tabWithBadgeActive: '',
            tabWithTooltipActive: '',
            tabWithDefaultTabActive: 'default_tab_2',
            tabWithLink: '',
            tabWithTargetLink: ''
        };
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Tab' : 'Edit Tab'
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        }
    },

    methods: {
        badgeCountChanged() {
            if (this.editedItem.badgeCount > 0) {
                this.editedItem.tooltipHeader = ''
                this.editedItem.tooltipBody = ''
            }
        },
        editItem(item) {
            this.editedIndex = this.demoTabOptions.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteItem(item) {
            this.editedIndex = this.demoTabOptions.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.deleteItemConfirm()
        },
        deleteItemConfirm() {
            this.demoTabOptions.splice(this.editedIndex, 1)
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            this.$validator.validateAll('default').then(valid => {
                if (!valid) {
                    this.type = 'error';
                    this.snackbar = true;
                } else {
                    this.type = 'success';
                    this.snackbar = false;
                    if (this.editedIndex > -1) {
                        Object.assign(this.demoTabOptions[this.editedIndex], this.editedItem)
                    } else {
                        this.demoTabOptions.push(this.editedItem)
                    }
                    this.close()
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.tabs-responsive-example-code {
    height: 320px
}
</style>