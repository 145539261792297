import { render, staticRenderFns } from "./TabsResponsive.vue?vue&type=template&id=605a0884&scoped=true&"
import script from "./TabsResponsive.vue?vue&type=script&lang=js&"
export * from "./TabsResponsive.vue?vue&type=script&lang=js&"
import style0 from "./TabsResponsive.vue?vue&type=style&index=0&id=605a0884&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605a0884",
  null
  
)

export default component.exports